import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";

export interface AddressFormData {
  chain: string;
  address: string;
  description: string;
  variant: string;
  sourceAddress?: string;
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: AddressFormData) {
  if (formData.sourceAddress) {
    const sourceAddress = formData.sourceAddress;
    return await httpClientInstance.createAddress([formData.chain], {
      variant: formData.variant as "internal" | "shared" | "external",
      key: sourceAddress,
      notes: { description: formData.description },
    });
  } else if (formData.address === "") {
    return await httpClientInstance.createAddress([formData.chain], {
      variant: "internal",
      notes: { description: formData.description },
    });
  } else {
    return await httpClientInstance.createAddress([formData.chain, formData.address], {
      variant: "external",
      address: formData.address,
      notes: { description: formData.description },
    });
  }
}

export function useGenerateAddressMutation(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof submitFormData>>,
    SubmitError,
    AddressFormData
  >,
) {
  return useMutation({
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("Address Form submitted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting address form:", error.message);
    },
    ...options,
  });
}
