import { useMutation } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { InviteCode } from "../../../lib/api/invite";

interface FormData {
  userName: string;
  code: InviteCode;
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: FormData) {
  return await httpClientInstance.create("Credential", [formData.userName], {
    variant: "invite",
    public_key: formData.code.publicKey,
  });
}

function useGenerateInviteCode() {
  return useMutation({
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("Form submitted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting form:", error.message);
    },
  });
}

export default useGenerateInviteCode;
