import { useMutation } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { Transfer } from "../../../lib/api/types";

async function retryTransfer(id: string): Promise<Transfer> {
  const result = await httpClientInstance.retryTransfer(id);
  if (result.success()) {
    return result.getResource();
  } else {
    throw new Error("Retry transfer failed");
  }
}

export function useRetryTransfer() {
  const mutation = useMutation<Transfer, Error, string>({
    mutationFn: retryTransfer,
  });

  return mutation;
}
