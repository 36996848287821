import { Formik } from "formik";
import { useState } from "react";

import { Form, ModalSubmitButton, MultiColumnRow } from "../../../../components/form";

import { Copy, Renew } from "@carbon/react/icons";
import { useModalSetters } from "../../../../components/CModal";
import { GenerateUserInviteCode, InviteCode } from "../../../../lib/api/invite";
import { UserPage } from "../../../../lib/api/types";

import { Button } from "@carbon/react";
import { commonNotificationsBuilders } from "../../../../stores/notifications";
import useGenerateInviteCode from "../../api/createGenerateInviteCode";
import { useUsers } from "../../api/useUsers";
import { UserDropdown } from "./UserDropdown";

//Todo: add validation for the code
const validate = () => (values: any) => {
  const errors: any = {};

  if (!values.code) {
    errors.code = "Required";
  }
  return errors;
};

const newCode = (): InviteCode => {
  const inviteCode = GenerateUserInviteCode();
  return inviteCode;
};

interface FormData {
  userName: string;
  code: InviteCode;
}

export default function GenerateUserInviteCodeFrom() {
  //may fail on pagination
  const users: UserPage = useUsers(1, 500, "") as UserPage;

  const modalSetters = useModalSetters();

  const { mutate } = useGenerateInviteCode();

  const [invitationCode, setInviteCode] = useState(() => newCode());

  const initialValues = {
    code: invitationCode,
    selectedUser: "",
    user: {},
  };

  const handleSubmit = (values: any, modalSetters: any) => {
    const { setLoading, setNotif } = modalSetters;

    const formData: FormData = {
      userName: values.selectedUser,
      code: values.code,
    };

    setLoading(true);
    const notif = { success: "User invite" };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isErrored = false;
    let errMsg = "";

    mutate(formData, {
      onError: (err: any) => {
        errMsg = err.message;
        setLoading(false);
        setNotif({ error: `Error ${errMsg}` });
      },
      onSuccess: (data) => {
        setLoading(false);
        if (data.operation.state === "authorizing") {
          const notifDetails = commonNotificationsBuilders.authorizing(
            data.operation.name?.split("/")[1],
          );
          return setNotif({
            success: notifDetails.message!,
            error: "",
          });
        }
        setNotif({ success: notif.success, error: "" });
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate()}
      validateOnMount={true}
      onSubmit={async (values) => {
        await handleSubmit(values, modalSetters);
      }}
    >
      {(formikProps) => (
        <Form style={{ minWidth: 400 }}>
          <MultiColumnRow>
            <UserDropdown users={users} formikProps={formikProps} />
          </MultiColumnRow>
          <MultiColumnRow>Send this invite code to the user for them to enroll.</MultiColumnRow>
          <br></br>
          <MultiColumnRow>
            <p>{initialValues.code.inviteCode}</p>
          </MultiColumnRow>
          <MultiColumnRow>
            <Button
              size="sm"
              kind="tertiary"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setInviteCode(newCode());
              }}
            >
              <Renew />
            </Button>
            <Button
              size="sm"
              kind="secondary"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                navigator.clipboard.writeText(initialValues.code.inviteCode);
              }}
            >
              <Copy />
            </Button>
          </MultiColumnRow>
          <ModalSubmitButton />
        </Form>
      )}
    </Formik>
  );
}
