import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { TreasuryUser } from "../types/treasuryUserType";

async function fetchAdminUser(userId: string): Promise<TreasuryUser> {
  const response = await fetch(`https://admin.cordialapis.com/v1/users/${userId}`, {
    credentials: "include",
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }
  return data;
}

export function useAdminUser(userId: string) {
  const queryKey = useMemo(() => ["admin-user", userId], [userId]);

  const { data, error, isLoading, isError } = useQuery<TreasuryUser, Error>({
    queryKey,
    queryFn: () => fetchAdminUser(userId),
  });

  return {
    data,
    isLoading,
    error,
    isError,
  };
}
