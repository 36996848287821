import { Checkbox, ComboBox, ContentSwitcher, NumberInput, Switch } from "@carbon/react";
import { Field, FieldProps } from "formik";
import React, { useEffect } from "react";

interface AmountSelectorProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  limits: boolean;
  setLimits: (value: boolean) => void;
  atLeast?: string;
  setAtLeast: (value?: string) => void;
  moreThan?: string;
  setMoreThan: (value?: string) => void;
  atMost?: string;
  setAtMost: (value?: string) => void;
  lessThan?: string;
  setLessThan: (value?: string) => void;
  within?: string;
  setWithin: (value?: string) => void;
  withinPeriod?: string;
  setWithinPeriod: (value: string) => void;
  selectedRow?: any;
}

const AmountSelector: React.FC<AmountSelectorProps> = ({
  setFieldValue,
  limits,
  setLimits,
  atLeast,
  setAtLeast,
  moreThan,
  setMoreThan,
  atMost,
  setAtMost,
  lessThan,
  setLessThan,
  setWithin,
  setWithinPeriod,
  selectedRow,
}) => {
  useEffect(() => {
    if (selectedRow) {
      if (selectedRow.amount) {
        setFieldValue("limits", true);
        setLimits(true);

        if (selectedRow.amount.at_least) {
          setFieldValue("atLeast", selectedRow.amount.at_least);
          setAtLeast(selectedRow.amount.at_least);
        }

        if (selectedRow.amount.more_than) {
          setFieldValue("moreThan", selectedRow.amount.more_than);
          setMoreThan(selectedRow.amount.more_than);
        }

        if (selectedRow.amount.at_most) {
          setFieldValue("atMost", selectedRow.amount.at_most);
          setAtMost(selectedRow.amount.at_most);
        }

        if (selectedRow.amount.less_than) {
          setFieldValue("lessThan", selectedRow.amount.less_than);
          setLessThan(selectedRow.amount.less_than);
        }

        if (selectedRow.amount.within) {
          setFieldValue("within", selectedRow.amount.within);
          setWithin(selectedRow.amount.within);
        }
      } else {
        setFieldValue("limits", false);
        setLimits(false);
      }
    }
  }, [
    selectedRow,
    setFieldValue,
    setLimits,
    setAtLeast,
    setMoreThan,
    setAtMost,
    setLessThan,
    setWithin,
  ]);

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ paddingBottom: "10px" }}>
          What amount limits would you like this Rule to apply to?
        </div>
        <ContentSwitcher
          onChange={(e) => {
            const applyLimits = e.name === "apply";
            setLimits(applyLimits);
            setFieldValue("limits", applyLimits);
          }}
          selectedIndex={limits ? 0 : 1}
          size="sm"
        >
          <Switch name="apply" text="Apply" />
          <Switch name="none" text="None" />
        </ContentSwitcher>
      </div>
      {limits && (
        <>
          <div>
            <div style={{ paddingBottom: "10px" }}>Lower bound (USD)</div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <NumberInput
                style={{ width: "800px" }}
                id="lowerBound"
                name="lowerBound"
                min={0}
                value={atLeast || moreThan || 0}
                onChange={(event, { value }) => {
                  const numericValue = Number(value);
                  if (!isNaN(numericValue) && numericValue >= 0) {
                    if (atLeast !== undefined) {
                      // meaning inclusive
                      setFieldValue("atLeast", `${numericValue}`);
                      setAtLeast(`${numericValue}`);
                    } else {
                      setFieldValue("moreThan", `${numericValue}`);
                      setMoreThan(`${numericValue}`);
                    }
                  } else {
                    setFieldValue("atLeast", undefined);
                    setAtLeast(undefined);
                    setFieldValue("moreThan", undefined);
                    setMoreThan(undefined);
                  }
                }}
              />
              <div
                style={{
                  paddingTop: "10px",
                }}
              >
                <Checkbox
                  id="inclusiveLowerBound"
                  name="inclusiveLowerBound"
                  labelText="Inclusive"
                  onChange={(event: any) => {
                    const inclusive = event.target.checked;
                    if (inclusive) {
                      setFieldValue("atLeast", moreThan);
                      setAtLeast(moreThan);
                      setFieldValue("moreThan", undefined);
                      setMoreThan(undefined);
                    } else {
                      setFieldValue("moreThan", atLeast);
                      setMoreThan(atLeast);
                      setFieldValue("atLeast", undefined);
                      setAtLeast(undefined);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div style={{ paddingBottom: "10px" }}>Upper bound (USD)</div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <NumberInput
                style={{ width: "800px" }}
                id="upperBound"
                name="upperBound"
                min={0}
                value={atMost || lessThan || 0}
                onChange={(event, { value }) => {
                  const numericValue = Number(value);
                  if (!isNaN(numericValue) && numericValue >= 0) {
                    if (atMost !== undefined) {
                      // meaning inclusive
                      setFieldValue("atMost", `${numericValue}`);
                      setAtMost(`${numericValue}`);
                    } else {
                      setFieldValue("lessThan", `${numericValue}`);
                      setLessThan(`${numericValue}`);
                    }
                  } else {
                    setFieldValue("atMost", undefined);
                    setAtMost(undefined);
                    setFieldValue("lessThan", undefined);
                    setLessThan(undefined);
                  }
                }}
              />
              <div
                style={{
                  paddingTop: "10px",
                }}
              >
                <Checkbox
                  id="inclusiveUpperBound"
                  name="inclusiveUpperBound"
                  labelText="Inclusive"
                  onChange={(event: any) => {
                    const inclusive = event.target.checked;
                    if (inclusive) {
                      setFieldValue("atMost", lessThan);
                      setAtMost(lessThan);
                      setFieldValue("lessThan", undefined);
                      setLessThan(undefined);
                    } else {
                      setFieldValue("lessThan", atMost);
                      setLessThan(atMost);
                      setFieldValue("atMost", undefined);
                      setAtMost(undefined);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div style={{ paddingBottom: "10px" }}>Within</div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <Field name="within">
                {({ field, form }: FieldProps) => (
                  <NumberInput
                    style={{ width: "700px" }}
                    id="within"
                    name="within"
                    min={0}
                    value={field.value || 0}
                    onChange={(event, { value }) => {
                      const numericValue = Number(value);
                      if (!isNaN(numericValue) && numericValue >= 0) {
                        form.setFieldValue(field.name, `${numericValue}`);
                        setWithin(`${numericValue}`);
                      } else {
                        form.setFieldValue(field.name, "0");
                        setWithin(`0`);
                      }
                    }}
                    invalid={!!form.errors.within}
                  />
                )}
              </Field>
              <Field
                id="withinPeriod"
                name="withinPeriod"
                placeholder="Select a period"
                component={ComboBox}
                items={[
                  {
                    id: "s",
                    label: "Seconds",
                  },
                  {
                    id: "m",
                    label: "Minutes",
                  },
                  {
                    id: "h",
                    label: "Hours",
                  },
                  {
                    id: "d",
                    label: "Days",
                  },
                  {
                    id: "w",
                    label: "Weeks",
                  },
                ]}
                onChange={(event: any) => {
                  const newPeriodValue = event.selectedItem?.id;
                  if (!newPeriodValue) {
                    setFieldValue("withinPeriod", newPeriodValue);
                    setWithinPeriod(newPeriodValue);
                  }
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AmountSelector;
