import { ComboBox, Loading, Modal, TextInput, Toggle } from "@carbon/react";
import { Warning } from "@carbon/react/icons";
import { useClerk } from "@clerk/clerk-react";
import { KEY_API, KEY_ORGANISATION, KEY_TREASURY, KEY_USER } from "lib/constants";
import React, { useEffect, useState } from "react";
import useCachedState from "../../hooks/useCachedState";
import { ParseName } from "../../lib/api/name";
import { useNotificationStore } from "../../stores/notifications";
import { useAdminUser } from "./api/getAdminUser";
import { useOrganizationAndTreasuries } from "./api/getOrganizationsAndTreasuries";
import { useTreasuries } from "./api/getTreasuries";
import { useModifyTreasuryMutation } from "./api/updateTreasury";
import { Organization } from "./types/organisationTypes";
import { Treasury } from "./types/treasuryTypes";

export interface Item {
  id: number;
  text: string;
  userName?: string;
  icon?: string;
}

interface OrgTreasuryEndPointSelectorProps {
  handleToggleChange: () => void;
}

const OrgTreasuryEndPointSelector: React.FC<OrgTreasuryEndPointSelectorProps> = () => {
  const { isLoading } = useTreasuries();
  const { data, error } = useOrganizationAndTreasuries();

  const [showInputs, setShowInputs] = useState(false);
  const handleToggleChange = () => setShowInputs(!showInputs);

  const [selectedOrgId, setSelectedOrgId] = useState<Organization | null>(null);
  const [userName, setUserName] = useState("");
  const [apiUrl, setAPIUrl] = useState("");
  const [invalidApiUrl, setInvalidApiUrl] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cachedApiURL, setCachedApiURL] = useCachedState<string>(KEY_API, "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cachedUser, setCachedUser] = useCachedState<string>(KEY_USER, "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cachedTreasury, setCachedTreasury] = useCachedState<string>(KEY_ORGANISATION, "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cachedOrganisation, setCachedOrganisation] = useCachedState<string>(KEY_TREASURY, "");

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [selectedTreasury, setSelectedTreasury] = useState<Treasury | null>(null);

  const handleOrgChange = (org: Organization) => {
    if (org) {
      setCachedOrganisation(org.slug);
      setSelectedOrgId(org);
    } else {
      console.error("Organization is undefined");
    }
  };

  const handleTreasuryChange = (treasury: Treasury) => {
    if (treasury) {
      setSelectedTreasury(treasury);
      setAPIUrl(treasury.api);
      setCachedTreasury(treasury.description);
    } else {
      console.error("Treasury is undefined");
    }
  };

  const filteredTreasuries = data?.userTreasuries.filter(
    (treasury: Treasury) => treasury.organization === selectedOrgId?.name,
  );

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleApiURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAPIUrl(event.target.value);
  };

  const onConfirm = () => {
    if (showInputs) {
      try {
        new URL(apiUrl);
        setInvalidApiUrl(false);
      } catch (_) {
        return setInvalidApiUrl(true);
      }

      apiUrl && setCachedApiURL(apiUrl);
      userName && setCachedUser(userName);
    } else {
      selectedTreasury?.api && setCachedApiURL(selectedTreasury.api);
    }
    window.location.replace(`https://${window.location.host}`);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        modalHeading="Select organization and treasury"
        size="sm"
        primaryButtonText="Confirm"
        onSecondarySubmit={handleCloseModal}
        primaryButtonDisabled={!!selectedTreasury && !selectedTreasury.api}
        onRequestSubmit={onConfirm}
        preventCloseOnClickOutside
      >
        {!showInputs && (
          <div>
            <ComboBox
              id="organisation"
              titleText="Organization"
              placeholder="Select an organization"
              selectedItem={selectedOrgId}
              items={data?.userOrganisations || []}
              itemToString={(item) => (item ? item.display_name : "")}
              onChange={(event) => handleOrgChange(event.selectedItem as Organization)}
            />
            <ComboBox
              id="treasuries"
              titleText="Treasury"
              placeholder="Select a treasury"
              items={
                filteredTreasuries?.map((treasury) => ({
                  id: treasury,
                  text: treasury.description ?? `Treasury ID: ${treasury.name.split("/")[1]}`,
                  treasury: treasury as Treasury,
                })) || []
              }
              itemToString={(item) => (item ? item.text : "")}
              onChange={(event) => handleTreasuryChange(event.selectedItem?.treasury as Treasury)}
            />

            {isLoading && <Loading withOverlay={true} />}
          </div>
        )}
        {showInputs && (
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <TextInput
              className="cds--form-item"
              labelText="API URL"
              name="apiURL"
              id="apiURL"
              value={apiUrl}
              onChange={handleApiURLChange}
              invalid={invalidApiUrl}
              invalidText="Invalid URL"
            />
            <TextInput
              className="cds--form-item"
              labelText="User"
              name="name"
              id="userNameInput"
              value={userName}
              onChange={handleUserNameChange}
            />
            {error && <div>Invalid URL</div>}
          </div>
        )}
        <Toggle id="toggle" labelText="Manual" onToggle={handleToggleChange} />
        {selectedTreasury && !selectedTreasury.api && (
          <div
            style={{
              border: "1px dashed #ffcc00",
              backgroundColor: "#ffcc001a",
              borderRadius: "5px",
              padding: "1rem",
              margin: "1rem 0",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Warning />
            <div>
              <span>
                Selected treasury does not have an API URL. Please refer to our{" "}
                <a href="https://docs.cordialsystems.com/guides/deployment/enrolling-treasury">
                  docs
                </a>{" "}
                to set treasury API URL.
              </span>
              <span
                style={{
                  display: "inline-block",
                }}
              >
                As you are logged in as an admin of this treasury, you can set it{" "}
                <a
                  href="#"
                  onClick={() => {
                    setIsEditModalOpen(true);
                    setIsModalOpen(false);
                  }}
                >
                  here
                </a>
                .
              </span>
            </div>
          </div>
        )}
      </Modal>
      {selectedTreasury && !selectedTreasury.api && (
        <EditTreasuryModal
          selectedTreasury={selectedTreasury}
          open={isEditModalOpen}
          handleClose={() => {
            setIsEditModalOpen(false);
            setIsModalOpen(true);
          }}
        />
      )}
    </>
  );
};

export default OrgTreasuryEndPointSelector;

function EditTreasuryModal({
  selectedTreasury,
  open,
  handleClose,
}: {
  selectedTreasury: Treasury;
  open: boolean;
  handleClose: () => void;
}) {
  const { addNotification } = useNotificationStore();

  const [treasuryDescription, setTreasuryDescription] = useState("");
  const [treasuryApiUrl, setTreasuryApiUrl] = useState("");
  const [invalidApiUrl, setInvalidApiUrl] = useState(false);

  const { user } = useClerk();
  const { data: adminUser } = useAdminUser(user?.id.split("_")[1] || "");
  const { data } = useOrganizationAndTreasuries();

  const { mutate, isPending } = useModifyTreasuryMutation();

  const currentTreasury = data?.userTreasuries.filter((t) => t.name === selectedTreasury.name)?.[0];

  useEffect(() => {
    setTreasuryDescription(currentTreasury?.description || "");
    setTreasuryApiUrl(currentTreasury?.api || "");
  }, [currentTreasury]);

  function handleSubmit() {
    if (!treasuryDescription || !treasuryApiUrl || invalidApiUrl || isPending || !currentTreasury) {
      return;
    }
    mutate(
      {
        treasuryId:
          (currentTreasury?.name && ParseName(currentTreasury.name, "Treasury")?.resourceId) || "",
        treasury: {
          ...currentTreasury,
          api: treasuryApiUrl,
          description: treasuryDescription,
        },
      },
      {
        onError: (err) => {
          console.error("Error updating treasury:", err);
          addNotification({
            type: "error",
            title: "Error updating treasury",
            message: err.message,
          });
        },
        onSuccess: () => {
          addNotification({
            type: "success",
            title: "Treasury updated",
            message: "Treasury updated successfully",
          });
          handleClose();
        },
      },
    );
  }

  if (!user) return null;
  if (adminUser?.organizations[currentTreasury?.organization ?? ""] !== "admin") return null;

  return (
    <Modal
      open={open}
      modalHeading="Edit Treasury"
      size="sm"
      primaryButtonText={isPending ? "Saving..." : "Save"}
      secondaryButtonText="Cancel"
      onRequestClose={handleClose}
      onRequestSubmit={handleSubmit}
      onSecondarySubmit={() => {
        handleClose();
        setTreasuryDescription(currentTreasury?.description || "");
        setTreasuryApiUrl(currentTreasury?.api || "");
      }}
      primaryButtonDisabled={!treasuryDescription || !treasuryApiUrl || invalidApiUrl || isPending}
    >
      <TextInput
        labelText="Treasury description"
        name="description"
        id="descriptionInput"
        placeholder="Enter treasury description"
        value={treasuryDescription}
        onChange={(e) => {
          setTreasuryDescription(e.target.value);
        }}
      />
      <TextInput
        labelText="API URL"
        name="apiURL"
        id="apiURL"
        placeholder="Enter API URL"
        value={treasuryApiUrl}
        onChange={(e) => {
          setTreasuryApiUrl(e.target.value);
          try {
            new URL(e.target.value);
            setInvalidApiUrl(false);
          } catch (error) {
            setInvalidApiUrl(true);
          }
        }}
        invalid={invalidApiUrl}
        invalidText="Invalid URL"
      />
    </Modal>
  );
}
