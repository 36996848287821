import { ContentSwitcher, FilterableMultiSelect, Switch } from "@carbon/react";
import React, { useEffect } from "react";
import { useAsset } from "../../../../features/assetManagement/api/getAsset";
import { ParseName } from "../../../../lib/api/name";
import { filterAsArray } from "./UserGroupSelector";

interface SelectItem {
  label: string;
  value: string;
}

interface AssetChainSelectorProps {
  mode: string;
  chainOptions: SelectItem[];
  assetsData: { assets: Array<{ name: string; contract: string }> };
  setMode: (mode: string) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setSelectedChains: (chains: SelectItem[]) => void;
  setSelectedAssets: (assets: SelectItem[]) => void;
  chainKey: number;
  assetKey: number;
  setChainKey: (key: number) => void;
  setAssetKey: (key: number) => void;
  assetItemRenderer: (item: SelectItem) => React.ReactNode;
  selectedRow?: any;
}

const AssetItemRenderer = (item: SelectItem) => {
  const { data: asset } = useAsset({ assetId: item.label });

  const parsedName = asset?.symbol
    ? ParseName([asset?.symbol], "Symbol")
    : ParseName(item.label || "", "Asset");
  return (
    <div>
      <div>{parsedName.resourceId}</div>
      <div style={{ display: "flex", gap: "100px" }}>
        <div>
          <strong>Chain:</strong> {parsedName.parentId}
        </div>
      </div>
    </div>
  );
};

const AssetChainSelector: React.FC<AssetChainSelectorProps> = ({
  mode,
  chainOptions,
  assetsData,
  setMode,
  setFieldValue,
  setSelectedChains,
  setSelectedAssets,
  chainKey,
  assetKey,
  setChainKey,
  setAssetKey,
  selectedRow,
}) => {
  useEffect(() => {
    if (selectedRow && selectedRow.asset) {
      if (selectedRow.asset === "any/asset") {
        setMode("all");
        setFieldValue("mode", "all");
        setSelectedAssets([]);
      } else if (Array.isArray(selectedRow.asset)) {
        const firstItem = selectedRow.asset[0];
        if (firstItem.startsWith("chains/") && firstItem.includes("/assets/")) {
          setMode("asset");
          setFieldValue("mode", "asset");

          const initialAssets = selectedRow.asset.map((asset: string) => {
            const assetName = asset.split("/").pop();
            return {
              label: assetName,
              value: asset,
            };
          });

          console.log("initialAssets", initialAssets);

          setSelectedAssets(initialAssets);

          //@ts-expect-error  exist
          setFieldValue("asset", initialAssets.map((item) => item.value).join(","));
        } else if (firstItem.startsWith("chains/")) {
          setMode("chain");
          setFieldValue("mode", "chain");

          const initialChains = selectedRow.asset.map((chain: string) => {
            const chainName = chain.split("/")[1];
            return {
              label: chainName,
              value: chainName,
            };
          });

          setSelectedChains(initialChains);
          //@ts-expect-error  exist
          setFieldValue("chain", initialChains.map((item) => item.value).join(","));
        }
      }
    }
  }, [selectedRow, setMode, setFieldValue, setSelectedAssets, setSelectedChains]);

  console.log("selectedRow", selectedRow);

  const extractContractAddress = (assetString: string) => {
    const parts = assetString.split("/");
    return parts[parts.length - 1];
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ paddingBottom: "10px" }}>
          What Assets / Chain would you like this Rule to apply to?
        </div>
        <ContentSwitcher
          onChange={(e) => {
            const selectedMode = e.name;
            setMode(selectedMode as string);
            setFieldValue("mode", selectedMode);
            setFieldValue("chain", "");
            setFieldValue("asset", "");
            setSelectedChains([]);
            setSelectedAssets([]);
            setChainKey(chainKey + 1);
            setAssetKey(assetKey + 1);
            setSelectedAssets([]);
          }}
          selectedIndex={mode === "asset" ? 0 : mode === "chain" ? 1 : 2}
          size="sm"
        >
          <Switch name="asset" text="Asset" />
          <Switch name="chain" text="Chain" disabled />
          <Switch name="all" text="All" />
        </ContentSwitcher>
      </div>

      {mode === "chain" && (
        <div style={{ marginBottom: "20px" }}>
          <FilterableMultiSelect
            key={chainKey}
            id="chain"
            filterItems={(items, filter) => {
              return items.filter((item) =>
                item.label.toLowerCase().includes(filter.inputValue?.toLowerCase() || ""),
              );
            }}
            titleText="Select Chain"
            placeholder="Choose a chain"
            items={chainOptions}
            initialSelectedItems={
              selectedRow?.asset?.startsWith("chains/") ? selectedRow.asset : []
            }
            itemToString={(item: SelectItem) => (item ? item.label : "")}
            onChange={(e) => {
              const selectedChainsValues = e.selectedItems.map((item: SelectItem) => item);
              setSelectedChains(selectedChainsValues);
              setFieldValue("chain", selectedChainsValues.map((item) => item.value).join(","));
            }}
          />
        </div>
      )}

      {mode === "asset" && (
        <div style={{ marginBottom: "20px" }}>
          <FilterableMultiSelect
            key={assetKey}
            id="asset"
            titleText="Select Asset"
            placeholder="Choose an asset"
            filterItems={(items, filter) => {
              return items.filter((item) =>
                item.label.toLowerCase().includes(filter.inputValue?.toLowerCase() || ""),
              );
            }}
            items={assetsData?.assets?.map((asset) => ({
              label: asset.name ?? "",
              value: asset.contract as string,
            }))}
            initialSelectedItems={
              selectedRow?.asset === "any/asset"
                ? [] // Use an empty array instead of null
                : filterAsArray(selectedRow.asset).map((asset) => ({
                    label: asset,
                    value: extractContractAddress(asset),
                    disabled: false,
                  }))
            }
            itemToString={(item) => (item ? item.label : "")}
            itemToElement={(item) => AssetItemRenderer(item as SelectItem)}
            onChange={(e) => {
              const selectedAssetValues = e.selectedItems.map((item: SelectItem) => item);
              setSelectedAssets(selectedAssetValues);
              setFieldValue("asset", selectedAssetValues.map((item) => item.value).join(","));
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AssetChainSelector;
