import { useMutation, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { Treasury } from "../types/treasuryTypes";

export interface UpdateTreasuryFormData {
  treasuryId: string;
  treasury: Treasury;
}

interface SubmitError {
  message: string;
}

async function submitModifyFormData(formData: UpdateTreasuryFormData) {
  const response = await fetch(
    `https://admin.cordialapis.com/v1/treasuries/${formData.treasuryId}`,
    {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData.treasury),
    },
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }
  return data;
}

export function useModifyTreasuryMutation(
  options?: UseMutationOptions<void, SubmitError, UpdateTreasuryFormData>,
): UseMutationResult<void, SubmitError, UpdateTreasuryFormData> {
  return useMutation<void, SubmitError, UpdateTreasuryFormData>({
    mutationFn: submitModifyFormData,
    onSuccess: (data: void, variables: UpdateTreasuryFormData, context: unknown) => {
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error: SubmitError, variables: UpdateTreasuryFormData, context: unknown) => {
      console.error("Error submitting treasury modified form:", error.message);
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
    ...options,
  });
}
