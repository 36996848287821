import {
  Content,
  Header,
  HeaderContainer,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderMenu,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  HeaderSideNavItems,
  InlineNotification,
  SideNav,
  SideNavItems,
  SkipToContent,
} from "@carbon/react";
import { Moon, Sun } from "@carbon/react/icons";
import { useUser } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import UserMenu from "../UserMenu";

interface NavItem {
  id: string;
  to: string;
  name: string;
  subItems?: NavItem[];
}

const NAV_ITEMS = [
  {
    id: "transfers",
    to: "/transfers",
    name: "Transfers",
    subItems: [
      {
        id: "transfers",
        to: "/transfers",
        name: "Ongoing Transfers",
      },
      {
        id: "deposits",
        to: "/deposits",
        name: "Historical Transfers",
      },
    ],
  },
  {
    id: "approvals",
    to: "/approvals",
    name: "Approvals",
  },
  {
    id: "policy",
    to: "/policy",
    name: "Policy",
  },
  {
    id: "accounts",
    to: "/accounts",
    name: "Accounts",
  },
  {
    id: "addresses",
    to: "/addresses",
    name: "Addresses",
  },
  {
    id: "assets",
    to: "/assets",
    name: "Assets",
  },
  {
    id: "users",
    to: "/users",
    name: "Users",
    subItems: [
      {
        id: "register-credentials",
        to: "/register-credentials",
        name: "Register Credentials",
      },
      {
        id: "users",
        to: "/users",
        name: "Users",
      },
      {
        id: "credentials",
        to: "/credentials",
        name: "Credentials",
      },
      {
        id: "roles",
        to: "/roles",
        name: "Roles",
      },
    ],
  },
  {
    id: "audit",
    to: "/audit",
    name: "Audit",
    subItems: [
      {
        id: "operations",
        to: "/operations",
        name: "Operations",
      },
    ],
  },
] satisfies NavItem[];

function CHeaderMenuItem({ to, children }: { to: string; children: React.ReactNode }) {
  return (
    <HeaderMenuItem
      as={NavLink}
      to={to}
      onClick={(e: { currentTarget: { blur: () => void } }) => e.currentTarget.blur()}
    >
      {children}
    </HeaderMenuItem>
  );
}

function Menu() {
  return (
    <>
      {NAV_ITEMS.map((item) => {
        if (item.subItems) {
          return (
            <HeaderMenu key={item.id} menuLinkName={item.name}>
              {item.subItems.map((subItem) => (
                <CHeaderMenuItem key={subItem.id} to={subItem.to}>
                  {subItem.name}
                </CHeaderMenuItem>
              ))}
            </HeaderMenu>
          );
        }
        return (
          <CHeaderMenuItem key={item.id} to={item.to}>
            {item.name}
          </CHeaderMenuItem>
        );
      })}
    </>
  );
}

function CHeader() {
  const ariaLabel = "Cordial Treasury";
  const darkMode = useDarkMode(false, {
    classNameDark: "cds--g90",
    classNameLight: "cds--g10",
  });

  const { isSignedIn } = useUser();

  return (
    <HeaderContainer
      render={({
        isSideNavExpanded,
        onClickSideNavExpand,
      }: {
        isSideNavExpanded: boolean;
        onClickSideNavExpand: () => void;
      }) => (
        <Header aria-label={ariaLabel}>
          <SkipToContent />
          <HeaderMenuButton
            aria-label={isSideNavExpanded ? "Close menu" : "Open menu"}
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName as={Link} to="/" prefix="">
            <img
              src={darkMode.value ? "/cordial_dark.svg" : "/cordial.svg"}
              width="36"
              alt={ariaLabel}
            />
          </HeaderName>
          <HeaderNavigation aria-label={ariaLabel}>
            <Menu />
          </HeaderNavigation>

          <HeaderGlobalBar>
            {isSignedIn && (
              <div style={{ display: "flex", alignItems: "center", paddingTop: "10px" }}>
                <HeaderGlobalAction aria-label="">
                  <UserMenu />
                </HeaderGlobalAction>
              </div>
            )}

            {darkMode.value ? (
              <HeaderGlobalAction
                aria-label="Light mode"
                tooltipAlignment="end"
                onClick={darkMode.disable}
              >
                <Sun size={20} />
              </HeaderGlobalAction>
            ) : (
              <HeaderGlobalAction
                aria-label="Dark mode"
                tooltipAlignment="end"
                onClick={darkMode.enable}
              >
                <Moon size={20} />
              </HeaderGlobalAction>
            )}
          </HeaderGlobalBar>
          <SideNav
            aria-label="Side navigation"
            isPersistent={false}
            expanded={isSideNavExpanded}
            onOverlayClick={onClickSideNavExpand}
            onSideNavBlur={onClickSideNavExpand}
          >
            <SideNavItems>
              <HeaderSideNavItems>
                <Menu />
              </HeaderSideNavItems>
            </SideNavItems>
          </SideNav>
        </Header>
      )}
    />
  );
}

export default function CLayout({ error = "" }) {
  return error ? (
    <>
      <br />
      <InlineNotification style={{ margin: "0 auto" }} hideCloseButton subtitle={error} title="" />
    </>
  ) : (
    <>
      <CHeader />
      <CHeaderBanner />
      <Content style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Outlet />
      </Content>
    </>
  );
}

function CHeaderBanner() {
  const [showBanner, setShowBanner] = useState(!localStorage.getItem("next-ui-dismiss"));

  useEffect(() => {
    if (localStorage.getItem("next-ui-redirect")) {
      window.location.href = "https://next.treasury.cordial.systems";
    }
  }, []);

  if (!showBanner) return null;

  return (
    <div
      style={{
        width: "100%",
        marginTop: "46px",
        backgroundColor: "#0043CE",
        color: "white",
        padding: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          Experience the fresh new look of your treasury! Head over to{" "}
          <a
            href="https://next.treasury.cordial.systems"
            style={{
              color: "white",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            next.treasury.cordial.systems
          </a>{" "}
          to explore our sleek design and let us know what you think!
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => setShowBanner(false)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setShowBanner(false);
            }
          }}
          style={{
            color: "white",
            cursor: "pointer",
          }}
        >
          x
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <label
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              color: "white",
              fontSize: "0.75rem",
              cursor: "pointer",
            }}
          >
            <input
              type="checkbox"
              defaultChecked={!!localStorage.getItem("next-ui-redirect")}
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem("next-ui-redirect", "true");
                } else {
                  localStorage.removeItem("next-ui-redirect");
                }
              }}
            />
            Always use next
          </label>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              color: "white",
              cursor: "pointer",
              fontSize: "0.75rem",
              alignSelf: "flex-end",
            }}
          >
            <input
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem("next-ui-dismiss", "true");
                } else {
                  localStorage.removeItem("next-ui-dismiss");
                }
              }}
            />
            Don&apos;t show again
          </label>
        </div>
      </div>
    </div>
  );
}
