import { Asset } from "@/lib/api/types";
import { Button } from "@carbon/react";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MultiColumnRow } from "../../../components/form";
import { useCombinedAccountAndAddress } from "../../../features/accountsManagement/hooks/useCombinedAccountAndAddress";
import { useGetAssetPage } from "../../../features/assetManagement/api/getAssetPage";
import { useUsers } from "../../../features/usersManagement/api/useUsers";
import { ParseName } from "../../../lib/api/name";
import { commonNotificationsBuilders, useNotificationStore } from "../../../stores/notifications";
import { TransferRuleFormData, useCreateTransferRule } from "../api/createTransferRule";
import { UpdateTransferRuleFormData, useUpdateTransferRule } from "../api/updateTransferRule";
import AccountAddressFromSelector from "./policyBuilder/AccountAddressFromSelector";
import AccountAddressToSelector from "./policyBuilder/AccountAddressToSelector";
import AmountSelector from "./policyBuilder/AmountSelector";
import AssetChainSelector from "./policyBuilder/AssetChainSelector";
import PermissionTypeSelector from "./policyBuilder/PermissionTypeSelector";
import PolicyDescriptionInput from "./policyBuilder/PolicyDescriptionInput";
import PreviewSummary from "./policyBuilder/PreviewSummary";
import UserGroupSelector from "./policyBuilder/UserGroupSelector";

interface SelectItem {
  label: string;
  value: string;
  id: string;
}

const TransferCreateForm: React.FC = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { selectedRow } = location.state || {};
  const [internalSelectedRow, setInternalSelectedRow] = useState(selectedRow);
  console.log("selectedRow", selectedRow);

  const {
    data: assetsData,
    isLoading: assetsLoading,
    error: assetsError,
  } = useGetAssetPage({ pageSize: 1000 });

  const {
    addressList,
    isLoading: addressesLoading,
    error: addressesError,
  } = useCombinedAccountAndAddress();

  const users = useUsers(1, 1000, "");

  const createTransferMutation = useCreateTransferRule();
  const createUpdateTransferMutation = useUpdateTransferRule();

  const [selectedChains, setSelectedChains] = useState<SelectItem[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<SelectItem[]>([]);
  const [selectedAccountsFrom, setSelectedAccountsFrom] = useState<SelectItem[]>([]);

  const [filteredAddressesFrom, setFilteredAddressesFrom] = useState<SelectItem[]>([]);
  const [selectedAddressesFrom, setSelectedAddressesFrom] = useState<SelectItem[]>([]);
  const [selectedAccountsTo, setSelectedAccountsTo] = useState<SelectItem[]>([]);
  const [filteredAddressesTo, setFilteredAddressesTo] = useState<SelectItem[]>([]);
  const [selectedAddressesTo, setSelectedAddressesTo] = useState<SelectItem[]>([]);
  const [mode, setMode] = useState<string>("all");
  const [accountAndAddressModeFrom, setAccountAndAddressModeFrom] = useState<string>("all");
  const [accountAndAddressModeTo, setAccountAndAddressModeTo] = useState<string>("all");
  const [chainKey, setChainKey] = useState(0);
  const [assetKey, setAssetKey] = useState(0);
  const [accountKeyFrom, setAccountKeyFrom] = useState(0);
  const [addressKeyFrom, setAddressKeyFrom] = useState(0);
  const [accountKeyTo, setAccountKeyTo] = useState(0);
  const [addressKeyTo, setAddressKeyTo] = useState(0);
  const [viewMode, setViewMode] = useState<string>("none");
  const [selectedGroups, setSelectedGroups] = useState<SelectItem[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<SelectItem[]>([]);
  const [limits, setLimits] = useState(false);
  const [atLeast, setAtLeast] = useState<string | undefined>(undefined);
  const [moreThan, setMoreThan] = useState<string | undefined>(undefined);
  const [atMost, setAtMost] = useState<string | undefined>(undefined);
  const [lessThan, setLessThan] = useState<string | undefined>(undefined);
  const [within, setWithin] = useState<string | undefined>(undefined);
  const [withinPeriod, setWithinPeriod] = useState("d");
  const { addNotification } = useNotificationStore();

  console.log("addressList", addressList);
  console.log("filtered", filteredAddressesFrom, filteredAddressesTo);

  console.log("accountAndAddressModeFrom", accountAndAddressModeFrom);
  useEffect(() => {
    if (selectedAccountsFrom.length > 0) {
      const filtered =
        addressList
          ?.filter((address) =>
            selectedAccountsFrom.some((account) => address.account === account.value),
          )
          .map((address) => ({
            label: address.name ?? "",
            value: address.name ?? "",
            id: address.name ?? "",
          })) ?? [];
      setFilteredAddressesFrom(filtered);
    } else {
      setFilteredAddressesFrom(
        addressList.map((address) => ({
          label: address.name || "",
          value: address.name || "",
          id: address.name || "",
        })),
      );
    }
  }, [selectedAccountsFrom, addressList]);

  useEffect(() => {
    if (selectedAccountsTo.length > 0) {
      const filtered =
        addressList
          ?.filter((address) =>
            selectedAccountsTo.some((account) => address.account === account.value),
          )
          .map((address) => ({
            label: address.name,
            value: address.name,
            id: address.name,
          })) ?? [];
      setFilteredAddressesTo(filtered as SelectItem[]);
    } else {
      setFilteredAddressesTo(
        addressList.map((address) => ({
          label: address.name || "",
          value: address.name || "",
          id: address.name || "",
        })) ?? [],
      );
    }
  }, [selectedAccountsTo, addressList]);

  if (assetsLoading || addressesLoading) {
    return <div>Loading...</div>;
  }

  if (assetsError || addressesError) {
    return <div>Error loading data</div>;
  }

  const chains = [
    ...new Set(assetsData?.assets?.map((asset: Asset) => asset?.name?.split("/")[1] ?? "")),
  ];

  const accounts = [...new Set(addressList?.map((address) => address.account ?? ""))];

  const chainOptions: SelectItem[] = chains.map((chain) => ({
    label: chain,
    value: chain,
    id: chain,
  }));

  const accountOptions: SelectItem[] = accounts
    .filter((account) => account) // Filter out empty or undefined accounts
    .map((account) => ({
      label: ParseName(account, "Account").resourceId,
      value: account,
      id: account,
    }));

  //@ts-expect-error - addressList is not null
  const groupOptions: SelectItem[] = [...new Set(users?.users?.flatMap((user) => user.roles))]
    .filter(Boolean)
    .map((group) => ({
      label: ParseName(group || "", "Role").resourceId,
      value: group,
      id: group,
    }));
  //@ts-expect-error - addressList is not null
  const userOptions: SelectItem[] = users?.users?.filter(Boolean).map((user) => ({
    label: ParseName(user.name || "", "User").resourceId,
    value: user.name ?? "",
    id: user.name ?? "",
  }));

  //selected row is directly passed so initial values are not working as expected
  const initialValues = {
    policyDescription: selectedRow?.notes?.description || "",
    permissionType: selectedRow?.variant || "allow",
    mode: "all",
    accountAndAddressModeTo: "all",
    chain: "",
    asset: "",
    accountAndAddressModeFrom: "all",
    accountFrom: "",
    addressFrom: "",
    accountTo: "",
    addressTo: "",
    viewMode: "none",
    group: "",
    user: "",
    numberOfApprovers: 0,
    atLeast: undefined,
    moreThan: undefined,
    atMost: undefined,
    lessThan: undefined,
    within: "0",
    withinPeriod: "d",
    limits: false,
  };

  const validate = (values: typeof initialValues) => {
    const errors: { [key: string]: string } = {};

    if (!values.permissionType) {
      errors.permissionType = "Permission Type is required";
    }

    if (values.mode === "asset" && !values.asset) {
      errors.asset = "Asset is required";
    }

    if (values.accountAndAddressModeFrom === "address" && !values.addressFrom) {
      errors.addressFrom = "Address From is required";
    }

    if (values.accountAndAddressModeTo === "address" && !values.addressTo) {
      errors.addressTo = "Address To is required";
    }

    return errors;
  };

  const onSubmit = (
    values: typeof initialValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    if (selectedRow) {
      // If selectedRow exists, perform an update
      handleUpdate(values, setSubmitting);
    } else {
      // Otherwise, create a new transfer rule
      handleCreate(values, setSubmitting);
    }
  };

  const handleCreate = (
    values: typeof initialValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const data: TransferRuleFormData = {
      policyName: "",
      policyDescription: values.policyDescription,
      asset: values.asset,
      from:
        values.accountAndAddressModeFrom === "all"
          ? "allAccountsAndAddressesFrom"
          : values.accountAndAddressModeFrom === "address"
            ? selectedAddressesFrom.map((address) => address.value).join(",")
            : selectedAccountsFrom.map((account) => account.value).join(","),
      to:
        values.accountAndAddressModeTo === "all"
          ? "allAccountsAndAddressesTo"
          : values.accountAndAddressModeTo === "address"
            ? selectedAddressesTo.map((address) => address.value).join(",")
            : selectedAccountsTo.map((account) => account.value).join(","),
      permissionType: values.permissionType,
      selectedAssetIds:
        mode === "all"
          ? ["allAssets"]
          : mode === "asset"
            ? selectedAssets.map((asset) => asset.label)
            : selectedChains.map((chain) => "chains/" + chain.value),
      selectedFromIds:
        values.accountAndAddressModeFrom === "all"
          ? ["allAccountsAndAddressesFrom"]
          : values.accountAndAddressModeFrom === "address"
            ? selectedAddressesFrom.map((address) => address.value)
            : selectedAccountsFrom.map((account) => account.value),
      selectedToIds:
        values.accountAndAddressModeTo === "all"
          ? ["allAccountsAndAddressesTo"]
          : values.accountAndAddressModeTo === "address"
            ? selectedAddressesTo.map((address) => address.value)
            : selectedAccountsTo.map((account) => account.value),
      selectedUserIds:
        viewMode === "group"
          ? selectedGroups.map((group) => group.value)
          : filteredUsers.map((user) => user.value),
      approvals: values.numberOfApprovers,
      amount:
        values.limits && (values.atLeast || values.moreThan || values.atMost || values.lessThan)
          ? {
              at_least: values.atLeast,
              more_than: values.moreThan,
              at_most: values.atMost,
              less_than: values.lessThan,
              within: values.within === "0" ? undefined : values.within + values.withinPeriod,
              quote: "chains/USD/assets/USD",
            }
          : undefined,
    };

    console.log("Creating rule with data", data);

    createTransferMutation.mutate(data, {
      onError: (err: any) => {
        console.error("Error submitting form", err);
        addNotification({
          type: "error",
          title: "Error submitting form",
        });
        setSubmitting(false);
      },
      onSuccess: (res) => {
        if (res.operation.state === "authorizing") {
          const notifDetails = commonNotificationsBuilders.authorizing(
            res.operation.name?.split("/")[1],
          );
          return addNotification({
            type: notifDetails.type,
            title: notifDetails.title,
            message: notifDetails.message,
          });
        } else
          addNotification({
            type: "success",
            title: "Policy Submitted successfully",
          });
        console.log("Transfer Rule Form submitted successfully");
        setSubmitting(false);
        navigate("/policy");
      },
    });
  };

  const handleUpdate = (
    values: typeof initialValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    console.log("updateinngggg");
    const data: UpdateTransferRuleFormData = {
      ...selectedRow,
      policyName: selectedRow?.name || "",
      policyDescription: values.policyDescription,
      asset: values.asset,
      from:
        accountAndAddressModeFrom === "all"
          ? "allAccountsAndAddressesFrom"
          : accountAndAddressModeFrom === "address"
            ? selectedAddressesFrom.map((address) => address.label).join(",")
            : selectedAccountsFrom.map((account) => account.label).join(","),
      to:
        accountAndAddressModeTo === "all"
          ? "allAccountsAndAddressesTo"
          : accountAndAddressModeTo === "address"
            ? selectedAddressesTo.map((address) => address.label).join(",")
            : selectedAccountsTo.map((account) => account.label).join(","),
      permissionType: values.permissionType,
      selectedAssetIds:
        mode === "all"
          ? ["allAssets"]
          : mode === "asset"
            ? selectedAssets.map((asset) =>
                asset.value.startsWith("chains/") ? asset.value : asset.label,
              )
            : selectedChains.map((chain) => "chains/" + chain.value),
      selectedFromIds:
        accountAndAddressModeFrom === "all"
          ? ["allAccountsAndAddressesFrom"]
          : accountAndAddressModeFrom === "address"
            ? selectedAddressesFrom.map((address) => address.label)
            : selectedAccountsFrom.map((account) => account.value),
      selectedToIds:
        accountAndAddressModeTo === "all"
          ? ["allAccountsAndAddressesTo"]
          : accountAndAddressModeTo === "address"
            ? selectedAddressesTo.map((address) => address.label)
            : selectedAccountsTo.map((account) => account.value),
      selectedUserIds:
        viewMode === "group"
          ? selectedGroups.map((group) => group.value)
          : filteredUsers.map((user) => user.value),
      approvals: values.numberOfApprovers,
      amount:
        values.limits && (values.atLeast || values.moreThan || values.atMost || values.lessThan)
          ? {
              at_least: values.atLeast,
              more_than: values.moreThan,
              at_most: values.atMost,
              less_than: values.lessThan,
              within: values.within === "0" ? undefined : values.within + values.withinPeriod,
              quote: "chains/USD/assets/USD",
            }
          : undefined,
      initialObject: selectedRow, // Pass the original object for update
    };

    console.log("Updating rule with data", data);

    createUpdateTransferMutation.mutate(data, {
      onError: (err: any) => {
        console.error("Error updating form", err);
        addNotification({
          type: "error",
          title: "Error updating form",
        });
        setSubmitting(false);
      },
      onSuccess: () => {
        addNotification({
          type: "success",
          title: "Policy Updated successfully",
        });
        console.log("Transfer Rule Form updated successfully");
        setSubmitting(false);
        navigate("/policy"); // Navigate back to the policies page after successful update
      },
    });
  };

  const extractDescription = (item: any): string => {
    const notes = item?.value.notes;
    console.log("notes", notes);
    if (!notes) return "";

    if (typeof notes === "object" && notes.description) {
      return `${notes.description}`;
    }

    if (typeof notes === "string") {
      try {
        const data = JSON.parse(notes);
        console.log("parsed data", data);
        return data.description || "";
      } catch (error) {
        console.error("Invalid JSON string provided:", error);
        return notes;
      }
    }

    return "";
  };

  const resetMultiSelectFrom = () => {
    setSelectedAccountsFrom([]); // Reset selected accounts
    setSelectedAddressesFrom([]); // Reset selected addresses
    setAccountKeyFrom((prev) => prev + 1); // Increment key to force re-render
    setAddressKeyFrom((prev) => prev + 1); // Increment key to force re-render
  };

  const resetMultiSelectTo = () => {
    setSelectedAccountsTo([]); // Reset selected accounts
    setSelectedAddressesTo([]); // Reset selected addresses
    setAccountKeyTo((prev) => prev + 1); // Increment key to force re-render
    setAddressKeyTo((prev) => prev + 1); // Increment key to force re-render
  };

  const assetItemRenderer = (item: SelectItem) => (
    console.log("item", item),
    (
      <div>
        <div>{ParseName(item.label || "", "Asset").resourceId}</div>
        <div style={{ display: "flex", gap: "100px" }}>
          <div>
            <strong>Chain:</strong> {ParseName(item.label || "", "Asset").parentId}
          </div>
        </div>
      </div>
    )
  );

  const itemRenderer = (item: SelectItem): React.ReactElement => {
    console.log("item", item);

    const parsedName = ParseName(item.label || "", "Address");
    const resourceId = parsedName.resourceId;
    const parentId = parsedName.parentId;
    const description = extractDescription(item);

    return (
      <div>
        <div>{resourceId}</div>
        <div style={{ display: "flex", gap: "100px" }}>
          <div>
            <strong>Chain:</strong> {parentId}
          </div>
          <div>
            <strong>Description:</strong> {description}
          </div>
        </div>
      </div>
    );
  };

  console.log(
    "preview-summary",

    // values,
    accountAndAddressModeFrom,
    // selectedAccountsFrom,
    selectedAddressesFrom,
    // accountAndAddressModeTo,
    // selectedAccountsTo,
    // selectedAddressesTo,
    // mode,
    // selectedChains,
    // selectedAssets,
    // viewMode,
    // selectedGroups,
    // filteredUsers,
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <Button style={{ marginBottom: "30px" }} size="sm" onClick={() => navigate("/policy")}>
            Back to Policies
          </Button>
          <MultiColumnRow>
            <div style={{ width: "100%" }}>
              {/* Description of the policy */}
              <div style={{ marginBottom: "20px" }}>
                <PolicyDescriptionInput name="policyDescription" />
              </div>

              {/* Permission type */}
              <PermissionTypeSelector
                name="permissionType"
                value={values.permissionType}
                setFieldValue={setFieldValue}
              />

              {/* Account and Address Mode From */}

              <AccountAddressFromSelector
                accountAndAddressModeFrom={accountAndAddressModeFrom}
                accountOptions={accountOptions}
                //@ts-expect-error  exist
                addressList={addressList}
                setAccountAndAddressModeFrom={setAccountAndAddressModeFrom}
                setFieldValue={setFieldValue}
                resetMultiSelectFrom={resetMultiSelectFrom}
                setSelectedAccountsFrom={(accounts) =>
                  setSelectedAccountsFrom(
                    accounts.map((account) => ({ ...account, id: account.value })),
                  )
                }
                setSelectedAddressesFrom={(addresses) =>
                  setSelectedAddressesFrom(
                    addresses.map((address) => ({ ...address, id: address.value })),
                  )
                }
                accountKeyFrom={accountKeyFrom}
                addressKeyFrom={addressKeyFrom}
                itemRenderer={itemRenderer}
                selectedRow={selectedRow}
              />

              {/* Account and Address Mode To */}
              <AccountAddressToSelector
                accountAndAddressModeTo={accountAndAddressModeTo}
                accountOptions={accountOptions}
                //@ts-expect-error  exist
                addressList={addressList}
                setAccountAndAddressModeTo={setAccountAndAddressModeTo}
                setFieldValue={setFieldValue}
                resetMultiSelectTo={resetMultiSelectTo}
                setSelectedAccountsTo={(accounts) =>
                  setSelectedAccountsTo(
                    accounts.map((account) => ({ ...account, id: account.value })),
                  )
                }
                setSelectedAddressesTo={(addresses) =>
                  setSelectedAddressesTo(
                    addresses.map((address) => ({ ...address, id: address.value })),
                  )
                }
                accountKeyTo={accountKeyTo}
                addressKeyTo={addressKeyTo}
                itemRenderer={itemRenderer}
                selectedRow={selectedRow}
              />

              {/* Asset and Chain Mode */}
              <AssetChainSelector
                mode={mode}
                chainOptions={chainOptions}
                //@ts-expect-error  exist
                assetsData={assetsData}
                setMode={setMode}
                setFieldValue={setFieldValue}
                setSelectedChains={(chains) =>
                  setSelectedChains(chains.map((chain) => ({ ...chain, id: chain.value })))
                }
                setSelectedAssets={(assets) =>
                  setSelectedAssets(assets.map((asset) => ({ ...asset, id: asset.value })))
                }
                chainKey={chainKey}
                assetKey={assetKey}
                setChainKey={setChainKey}
                setAssetKey={setAssetKey}
                assetItemRenderer={(item) => assetItemRenderer(item as SelectItem)}
                selectedRow={internalSelectedRow}
                setInternalSelectedRow={setInternalSelectedRow}
              />

              {/* User and Group Selection */}
              <UserGroupSelector
                viewMode={viewMode}
                groupOptions={groupOptions}
                userOptions={userOptions}
                setViewMode={setViewMode}
                setFieldValue={setFieldValue}
                setSelectedGroups={setSelectedGroups}
                setFilteredUsers={setFilteredUsers}
                selectedRow={internalSelectedRow}
              />

              <AmountSelector
                setFieldValue={setFieldValue}
                limits={limits}
                setLimits={setLimits}
                atLeast={atLeast}
                setAtLeast={setAtLeast}
                moreThan={moreThan}
                setMoreThan={setMoreThan}
                atMost={atMost}
                setAtMost={setAtMost}
                lessThan={lessThan}
                setLessThan={setLessThan}
                within={within}
                setWithin={setWithin}
                withinPeriod={withinPeriod}
                setWithinPeriod={setWithinPeriod}
                selectedRow={internalSelectedRow}
              />
            </div>
            {/* left side */}
            <div
              style={{
                width: "100%",
                padding: "40px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "400px",
                maxWidth: "90%",
              }}
            >
              {/* summary */}
              <div style={{ marginBottom: "20px", maxWidth: "90%", width: "90%" }}>
                <PreviewSummary
                  values={values}
                  accountAndAddressModeFrom={accountAndAddressModeFrom}
                  selectedAccountsFrom={selectedAccountsFrom}
                  selectedAddressesFrom={selectedAddressesFrom}
                  accountAndAddressModeTo={accountAndAddressModeTo}
                  selectedAccountsTo={selectedAccountsTo}
                  selectedAddressesTo={selectedAddressesTo}
                  mode={mode}
                  selectedChains={selectedChains}
                  selectedAssets={selectedAssets}
                  viewMode={viewMode}
                  selectedGroups={selectedGroups}
                  filteredUsers={filteredUsers}
                  limits={limits}
                  atLeast={atLeast}
                  moreThan={moreThan}
                  atMost={atMost}
                  lessThan={lessThan}
                  within={within}
                  withinPeriod={withinPeriod}
                />
              </div>

              <div style={{ flex: "1" }}></div>
              <div style={{ padding: "10px", marginBottom: "20px", color: "red" }}>
                <ErrorMessage name="policyDescription" component="div" />
                <ErrorMessage name="permissionType" component="div" />
                <ErrorMessage name="accountFrom" component="div" />
                <ErrorMessage name="addressFrom" component="div" />
                <ErrorMessage name="accountTo" component="div" />
                <ErrorMessage name="addressTo" component="div" />
                <ErrorMessage name="chain" component="div" />
                <ErrorMessage name="asset" component="div" />
                <ErrorMessage name="group" component="div" />
                <ErrorMessage name="user" component="div" />
                <ErrorMessage name="numberOfApprovers" component="div" />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </div>
            </div>
          </MultiColumnRow>
        </Form>
      )}
    </Formik>
  );
};

export default TransferCreateForm;
